import React, { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import {
  CreateCarrierState,
  CreateCarrierActions,
} from "store/ducks/settings/carriers";
import { ListCitiesActions, ListCitiesState } from "store/ducks/cities";
import { CepActions, CepState } from "store/ducks/cep";
import { useValidation, useTranslation } from "hooks";
import { translations } from "./translations";
import { validaCNPJ } from "utils/formatters";

import * as S from "./styles";
import { MainContainer } from "components/shared";
import { Input, Select, InputMask, ToggleInput } from "components/shared/Form";

import { statesOptions } from "utils/data/states";
import { ICitiesOptions } from "interfaces/city";
import { ListCountriesActions, ListCountriesState } from "store/ducks/country";

export const NewCarrier: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);
  const [isExterior, setIsExterior] = useState(false);
  const typeCarrier = [
    {
      value: "Contratada",
      label: getTranslation("tipo_contratada"),
    },
    {
      value: "Redespacho",
      label: getTranslation("tipo_redespacho"),
    },
    {
      value: "FOB",
      label: "FOB",
    },
  ];

  const { loading } = useSelector<RootStateOrAny>(
    (state) => state.createCompany
  ) as CreateCarrierState;

  const { data: dataCities, loading: loadingCities } =
    useSelector<RootStateOrAny>((state) => state.listCities) as ListCitiesState;

  const { loading: loadingCep } = useSelector<RootStateOrAny>(
    (state) => state.cep
  ) as CepState;

  const { data: dataCountries, loading: loadingCountries } = useSelector<
    RootStateOrAny,
    ListCountriesState
  >((state) => state.listCountries);

  const onSuccess = useCallback(() => {
    history.push("/settings/carriers");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      const obrigatorio = getTranslation("obrigatorio");

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          cnpj: Yup.string()
            .test("validaCNPJ", "CNPJ Inválido", (value: any) =>
              validaCNPJ(value)
            )
            .required(obrigatorio),
          company_name: Yup.string().required(obrigatorio),
          trade_name: Yup.string().required(obrigatorio),
          carrier_code: Yup.mixed()
            .test("codeValidation", "Código inválido", (value: any) =>
              Number.isInteger(Math.abs(Number(value)))
            )
            .required(obrigatorio),
          email: Yup.array()
            .transform(function (value, originalValue) {
              if (this.isType(value) && value !== null) {
                return value;
              }
              return originalValue ? originalValue.split(/[\s;]+/) : [];
            })
            .of(
              Yup.string().email(
                ({ value }) => `"${value}" não é um e-mail válido`
              )
            )
            .min(1, obrigatorio),
          address_street: Yup.string().required(obrigatorio),
          address_number: Yup.string().required(obrigatorio),
          address_neighborhood: Yup.string().required(obrigatorio),
          address_zipcode: Yup.string().required(obrigatorio),
          address_city: Yup.string().required(obrigatorio),
          address_state: Yup.string().required(obrigatorio),
          frete_weight: Yup.string(),
          safe: Yup.string(),
          pedagio: Yup.string(),
          type_carrier: Yup.string().required(obrigatorio),
          general_country_id: Yup.string().required(obrigatorio),
        });

        const exteriorSchema = Yup.object().shape({
          cnpj: Yup.string(),
          company_name: Yup.string().required(obrigatorio),
          trade_name: Yup.string().required(obrigatorio),
          carrier_code: Yup.mixed()
            .test("codeValidation", "Código inválido", (value: any) =>
              Number.isInteger(Math.abs(Number(value)))
            )
            .required(obrigatorio),
          email: Yup.array()
            .transform(function (value, originalValue) {
              if (this.isType(value) && value !== null) {
                return value;
              }
              return originalValue ? originalValue.split(/[\s;]+/) : [];
            })
            .of(
              Yup.string().email(
                ({ value }) => `"${value}" não é um e-mail válido`
              )
            )
            .min(1, obrigatorio),
          address_street: Yup.string().required(obrigatorio),
          address_number: Yup.string().required(obrigatorio),
          address_neighborhood: Yup.string().required(obrigatorio),
          address_zipcode: Yup.string().required(obrigatorio),
          address_city: Yup.string().required(obrigatorio),
          address_state: Yup.string(),
          frete_weight: Yup.string(),
          safe: Yup.string(),
          pedagio: Yup.string(),
          type_carrier: Yup.string().required(obrigatorio),
          general_country_id: Yup.string().required(obrigatorio),
        });

        const validator = isExterior ? exteriorSchema : schema;
        await validator.validate(data, {
          abortEarly: false,
        });

        if (isExterior) {
          data.cnpj_root = data.cnpj.replace(/[^0-9]/g, "");
          data.address_id_city_ibge = "9999999";
        }

        data.cnpj = data.cnpj.replace(/[^0-9]/g, "");
        if (!isExterior) {
          data.cnpj_root = data.cnpj.replace(/[^0-9]/g, "").slice(0, 8);
        }
        data.phone_number = data.phone_number.replace(/[^0-9]/g, "");
        delete data.is_exterior;
        dispatch(CreateCarrierActions.request(data, onSuccess));
      } catch (error) {
        /* email validation hack */
        if (error instanceof Yup.ValidationError) {
          const indexMailError = error.inner.findIndex((err) =>
            err?.path?.includes("email")
          );
          if (indexMailError !== -1) {
            error.inner[indexMailError].path = "email";
          }
        }
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess, getTranslation, isExterior]
  );

  const fetchCities = useCallback(
    (option) => {
      dispatch(ListCitiesActions.request({ state: option.value }));
    },
    [dispatch]
  );

  const setIbge = useCallback((ibge) => {
    if (formRef.current)
      return formRef.current.setFieldValue("address_id_city_ibge", ibge);
  }, []);

  const putDataInFields = useCallback(
    (data) => {
      if (!data && formRef.current) {
        return formRef.current.setFieldError(
          "address_zipcode",
          "CEP não encontrado"
        );
      }
      const stateOption = statesOptions.filter(
        (stateOption) => stateOption.value === data.address_state
      );

      const setCityForm = (cities: ICitiesOptions[]) => {
        const cityOption = cities.filter(
          (city) => city.ibge === data.address_id_city_ibge
        );

        if (cityOption.length > 0) {
          setIbge(cityOption[0].ibge);

          if (formRef.current) {
            formRef.current.setFieldValue("address_city", cityOption[0]);
          }
        }
      };

      dispatch(
        ListCitiesActions.request({ state: data.address_state }, setCityForm)
      );

      if (stateOption.length > 0 && formRef.current) {
        formRef.current.setFieldValue("address_street", data.address_street);
        formRef.current.setFieldValue(
          "address_neighborhood",
          data.address_neighborhood
        );
        formRef.current.setFieldValue("address_state", stateOption[0]);
      }
    },
    [dispatch, setIbge]
  );

  const fetchCep = useCallback(
    (cep) => {
      if (!isExterior) {
        formRef.current?.setFieldError("address_zipcode", "");
        const cleanCEP = cep ? cep.replace(/[^0-9]/g, "") : null;
        if (cleanCEP && cleanCEP.length < 8) {
          return formRef.current?.setFieldError(
            "address_zipcode",
            "CEP inválido"
          );
        }
        if (cleanCEP && cleanCEP.length === 8) {
          dispatch(CepActions.request(cleanCEP, putDataInFields));
        }
      }
    },
    [dispatch, putDataInFields, isExterior]
  );

  const getListCountries = useCallback(() => {
    dispatch(ListCountriesActions.request({ all: true, permission: true }));
  }, [dispatch]);

  useEffect(() => {
    getListCountries();
  }, [getListCountries]);

  return (
    <MainContainer>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("configuracoes")}{" "}
          <span>{getTranslation("novaTransportadora")}</span>
        </h1>
        <S.HeaderButtons>
          <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
            <S.IconArrowLeft />
            {getTranslation("voltar")}
          </S.ButtonMini>
        </S.HeaderButtons>
      </S.PageHeader>
      <S.PageContent>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{ picking_manage: true }}
        >
          <S.BoxContainer>
            <S.FormRow>
              <ToggleInput
                checked={isExterior}
                name="is_exterior"
                label={
                  isExterior
                    ? getTranslation("estrangeiro")
                    : getTranslation("nacional")
                }
                onChange={() => setIsExterior(!isExterior)}
              />
              <InputMask
                name="cnpj"
                label={
                  isExterior
                    ? getTranslation("idExterior")
                    : getTranslation("cnpj")
                }
                mask={isExterior ? "99999999999" : "99.999.999/9999-99"}
              />
              <Input
                name="company_name"
                label={getTranslation("razaoSocial")}
              />
              <Input name="trade_name" label={getTranslation("nomeFantasia")} />
              <Input name="carrier_code" label={getTranslation("codigoSAP")} />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="type_carrier"
                label={getTranslation("type")}
                options={typeCarrier}
                placeholder={getTranslation("selecione")}
              />

              <Input
                name="inscricao_estadual"
                label={getTranslation("inscricaoEstatual")}
                disabled={isExterior}
              />
              {isExterior ? (
                <Input name="phone_number" label={getTranslation("telefone")} />
              ) : (
                <InputMask
                  name="phone_number"
                  label={getTranslation("telefone")}
                  mask="(99) 9999-99999"
                />
              )}

              <Input
                name="email"
                label={getTranslation("emails")}
                placeholder="Utilize ; para separar os e-mails"
              />
            </S.FormRow>
            <S.FormRow>
              <InputMask
                name="address_zipcode"
                label={
                  isExterior
                    ? getTranslation("postalCode")
                    : getTranslation("cep")
                }
                onBlur={(e: any) => fetchCep(e.target.value)}
                isLoading={loadingCep}
                mask={isExterior ? "********" : "99999-999"}
              />
              <Input
                name="address_street"
                label={getTranslation("lougradouro")}
              />
              <Input name="address_number" label={getTranslation("numero")} />
              <Input
                name="address_neighborhood"
                label={getTranslation("bairro")}
              />
            </S.FormRow>
            <S.FormRow>
              {isExterior ? (
                <>
                  <Input
                    name="address_state"
                    label={getTranslation("provincy")}
                    // disabled={isExterior}
                  />
                  <Input
                    name="address_city"
                    label={getTranslation("cidade")}
                    // disabled={isExterior}
                  />
                </>
              ) : (
                <>
                  <Select
                    name="address_state"
                    label={getTranslation("uf")}
                    options={statesOptions}
                    placeholder={getTranslation("selecione")}
                    onChange={(e) => fetchCities(e)}
                  />
                  <Select
                    name="address_city"
                    label={getTranslation("cidade")}
                    isDisabled={loadingCities}
                    isLoading={loadingCities}
                    options={dataCities}
                    placeholder={getTranslation("selecione")}
                    onChange={(e: any) => setIbge(e.ibge)}
                  />
                </>
              )}

              <Input
                name="address_latitude"
                label={getTranslation("latitude")}
              />
              <Input
                name="address_longitude"
                label={getTranslation("longitude")}
              />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="general_country_id"
                label={getTranslation("pais")}
                options={dataCountries}
                isLoading={loadingCountries}
                isDisabled={loadingCountries}
                onChange={(e: any) => setIsExterior(e?.value !== 32)}
                placeholder={getTranslation("selecione")}
              />
              <S.Ghost />
              <S.Ghost />
              <S.Ghost />
            </S.FormRow>
            <S.Separator />
            <S.FormRow>
              <Input name="frete_weight" label={getTranslation("fretePeso")} />
              <Input name="safe" label={getTranslation("seguro")} />
              <Input name="pedagio" label={getTranslation("pedagio")} />
            </S.FormRow>
            <S.FormRow>
              <ToggleInput
                name="picking_manage"
                label={getTranslation("gerencia")}
                initialValue={true}
              />
              <ToggleInput
                name="is_granel"
                label={getTranslation("granel")}
                initialValue={false}
              />
            </S.FormRow>
            <Input
              name="address_id_city_ibge"
              hidden
              containerStyle={{ position: "absolute" }}
            />
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                {getTranslation("cancelar")}
              </S.Button>
              <S.Button type="submit">
                {loading ? <S.Loading /> : getTranslation("cadastrar")}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </MainContainer>
  );
};
