import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { useTranslation, usePermissions } from "hooks";
import { translations } from "./translations";
import { MainContainer, MenuSelector, Paginator } from "components/shared";
import {
  PaginateTransitsArActions,
  PaginateTransitsArState,
} from "store/ducks/settings/transit-time-ar";
import {
  SelectedFilterActions,
  // SelectedFilterState
} from "store/ducks/filter";
import { GridTransitTimeAr } from "components/settings/TransitTimeAr";

export const ListTransitTimes: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);
  const { checkUserPermission } = usePermissions();

  const [query, setQuery] = useState({
    limit: 10,
    page: 1,
  });

  const { data, loading, pagination } = useSelector<RootStateOrAny>(
    (state) => state.paginateTransitsAr
  ) as PaginateTransitsArState;

  const getData = useCallback(() => {
    dispatch(PaginateTransitsArActions.request({ ...query }));
  }, [dispatch, query]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handlePageChange = useCallback(
    (value) => {
      const data = {
        page: value,
      };
      dispatch(SelectedFilterActions.success(data));
      setQuery((state) => ({ ...state, data }));
    },
    [setQuery, dispatch]
  );

  return (
    <MainContainer>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("configuracoes")}{" "}
          <MenuSelector page="transit-time-ar" />
          {false && <S.LoadingPage />}
        </h1>
        <S.HeaderButtons>
          {checkUserPermission("insertProduct") && (
            <S.ButtonMini
              onClick={() => history.push("/settings/transit-time-ar/new")}
            >
              {getTranslation("transitTime")}
            </S.ButtonMini>
          )}
          {/* <FilterProducts onFilter={handleSubmitFilter} /> */}
        </S.HeaderButtons>
      </S.PageHeader>
      <S.PageContent>
        <GridTransitTimeAr
          transits={data}
          loading={loading}
          onDelete={getData}
        />
        <Paginator
          pagination={pagination}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </S.PageContent>
    </MainContainer>
  );
};

export default ListTransitTimes;
