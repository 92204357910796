import { MainContainer } from "components/shared";
import React, { useCallback, useRef, useEffect } from "react";
import * as S from "./styles";
import { useTranslation, useValidation } from "hooks";
import { translations } from "./translations";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  ListCarriersActions,
  ListCarriersState,
} from "store/ducks/settings/carriers";
import { ListCountriesActions, ListCountriesState } from "store/ducks/country";
import { FormHandles, SubmitHandler } from "@unform/core";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import { Input, Select, InputMask } from "components/shared/Form";
import * as Yup from "yup";


import { OptionTypeBase } from "react-select";
import { CreateTransitTimeArActions, CreateTransitTimeArState } from "store/ducks/settings/transit-time-ar";

interface CountSelectDataProps extends OptionTypeBase {}
export const countSelectData: CountSelectDataProps[] = [
  { label: "UTEIS", value: "UTEIS" },
  { label: "CORRIDOS", value: "CORRIDOS" },
];

const NewTransitTime = () => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const { loading } = useSelector<RootStateOrAny>((state)=> state.createTransitTimeAr) as CreateTransitTimeArState
  const { data: dataCarriers, loading: loadingCarriers } = useSelector<
    RootStateOrAny,
    ListCarriersState
  >((state) => state.listCarriers) as ListCarriersState;

  const { data: dataCountries, loading: loadingCountries } = useSelector<
    RootStateOrAny,
    ListCountriesState
  >((state) => state.listCountries) as ListCountriesState;

  const onSuccess = useCallback(() => {
    history.push("/settings/transit-time-ar");
  }, [history]);

  const fetchCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }));
  }, [dispatch]);

  const fetchCountries = useCallback(() => {
    dispatch(ListCountriesActions.request({ all: true }));
  }, [dispatch]);

  const handleSubmit = useCallback<SubmitHandler>(async (data) => {
    try{
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        carrier_id: Yup.string().required(getTranslation("obrigatorio")),
        country_origin: Yup.string().required(getTranslation("obrigatorio")),
        country_destiny: Yup.string().required(getTranslation("obrigatorio")),
        zipcode_origin: Yup.string().required(getTranslation("obrigatorio")),
        zipcode_destiny: Yup.string().required(getTranslation("obrigatorio")),
        weight: Yup.string().required(getTranslation("obrigatorio")),
        start: Yup.string().required(getTranslation("obrigatorio")),
        count_fractional: Yup.string().required(
          getTranslation("obrigatorio")
        ),
        deadline_fractional: Yup.string().required(
          getTranslation("obrigatorio")
        ),
        count_dedicated: Yup.string().required(getTranslation("obrigatorio")),
        deadline_dedicated: Yup.string().required(
          getTranslation("obrigatorio")
        ),
        valid_until: Yup.string().required(getTranslation("obrigatorio")),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      data.type = "CIDADE"

      dispatch(CreateTransitTimeArActions.request(data,onSuccess))

    } catch(error){
      handleFormErrors(error, formRef);
    }
  }, [
    dispatch,
    getTranslation,
    handleFormErrors,
    onSuccess,
  ]);
  useEffect(() => {
    fetchCarriers();
    fetchCountries();
  }, [fetchCarriers, fetchCountries]);

  return (
    <MainContainer>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("configuracoes")}{" "}
          <span>{getTranslation("transitTime")}</span>
        </h1>
        <S.HeaderButtons>
          <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
            <S.IconArrowLeft />
            {getTranslation("voltar")}
          </S.ButtonMini>
        </S.HeaderButtons>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <Select
              name="carrier_id"
              label={getTranslation("transportadora")}
              isDisabled={loadingCarriers}
              isLoading={loadingCarriers}
              options={dataCarriers}
              placeholder={getTranslation("selecione")}
            />
            <S.FormRow>
              <Select
                name="country_origin"
                label={`${getTranslation("countryOri")}`}
                isDisabled={loadingCountries}
                isLoading={loadingCountries}
                options={dataCountries}
                placeholder={getTranslation("selecione")}
              />
              <Input
                name="zipcode_origin"
                label={getTranslation("zipcodeOri")}
              />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="country_destiny"
                label={`${getTranslation("countryDes")}`}
                isDisabled={loadingCountries}
                isLoading={loadingCountries}
                options={dataCountries}
                placeholder={getTranslation("selecione")}
              />
              <Input
                name="zipcode_destiny"
                label={getTranslation("zipcodeDes")}
              />
            </S.FormRow>
            <S.FormRow>
              <Input name="weight" label={getTranslation("peso")} />
              <Input name="start" label={getTranslation("inicio")} />
              <InputMask
                name="cut_hour"
                label={getTranslation("horaCorte")}
                mask="99:99"
              />
              <Input
                name="valid_until"
                type="date"
                label={getTranslation("validade")}
              />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="count_fractional"
                label={getTranslation("contagemFracionada")}
                options={countSelectData}
                placeholder={getTranslation("selecione")}
              />
              <Input
                name="deadline_fractional"
                type="number"
                label={getTranslation("prazoFracionada")}
              />
              <Select
                name="count_dedicated"
                label={getTranslation("contagemLotacao")}
                options={countSelectData}
                placeholder={getTranslation("selecione")}
              />
              <Input
                name="deadline_dedicated"
                type="number"
                label={getTranslation("prazoLotacao")}
              />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                {getTranslation("cancelar")}
              </S.Button>
              
              <S.Button type="submit">
                {loading ? <S.Loading /> : getTranslation("cadastrar")}
              </S.Button>

            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </MainContainer>
  );
};

export default NewTransitTime;
