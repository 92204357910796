import React, { useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import {
  UpdateZipcodeInternacionalActions,
  UpdateZipcodeInternacionalState,
  FetchZipcodeInternacionalActions,
  FetchZipcodeInternacionalState,
} from "store/ducks/settings/zipcode-internacional";

import { ListCountriesActions, ListCountriesState } from "store/ducks/country";

import { useValidation, useTranslation } from "hooks";
import { translations } from "./translations";

import * as S from "./styles";
import { MainContainer } from "components/shared";
import { Input, Select } from "components/shared/Form";

interface IParams {
  id: string;
}

export const EditZipcodeInternacional: React.FC = () => {
  const { id } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const { loading } = useSelector<
    RootStateOrAny,
    FetchZipcodeInternacionalState
  >((state) => state.fetchZipcodeInternacional);

  const { loading: updateLoading } = useSelector<
    RootStateOrAny,
    UpdateZipcodeInternacionalState
  >((state) => state.updateZipcodeInternacional);

  const { data: dataCountries, loading: loadingCountries } = useSelector<
    RootStateOrAny,
    ListCountriesState
  >((state) => state.listCountries);

  const onSuccess = useCallback(() => {
    history.push("/settings/zipcode-internacional");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          general_country_id: Yup.string().required(
            getTranslation("obrigatorio")
          ),
          zipcode: Yup.string().required(getTranslation("obrigatorio")),
          city: Yup.string().required(getTranslation("obrigatorio")),
          state: Yup.string().required(getTranslation("obrigatorio")),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(
          UpdateZipcodeInternacionalActions.request(id, data, onSuccess)
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, id, onSuccess, getTranslation]
  );

  const putDataInSelect = useCallback(
    (data) => {
      if (formRef.current) {
        if (dataCountries) {
          const options = dataCountries.filter(
            (el) => el.value === data.general_country_id
          );
          formRef.current.setFieldValue("general_country_id", options[0]);
        }
      }
    },
    [dataCountries]
  );

  const onFetchSuccess = useCallback(
    (data) => {
      if (formRef.current) {
        formRef.current.setData(data);
        putDataInSelect(data);
      }
    },
    [putDataInSelect]
  );

  const fetchData = useCallback(() => {
    if (id) {
      dispatch(FetchZipcodeInternacionalActions.request(id, onFetchSuccess));
    }
  }, [dispatch, id, onFetchSuccess]);

  const getListCountries = useCallback(() => {
    dispatch(ListCountriesActions.request({ all: true, permission: true }));
  }, [dispatch]);

  useEffect(() => {
    getListCountries();
  }, [getListCountries]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <MainContainer>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("configuracoes")}{" "}
          <span>{getTranslation("editarZipcodeInternacional")}</span>
          {loading && <S.LoadingPage />}
        </h1>
        <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
          <S.IconArrowLeft />
          {getTranslation("voltar")}
        </S.ButtonMini>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Select
                name="general_country_id"
                label={getTranslation("pais")}
                options={dataCountries}
                isLoading={loadingCountries}
                isDisabled={loadingCountries}
                placeholder={getTranslation("selecione")}
                required
              />

              <Input
                name="zipcode"
                label={getTranslation("zipcode")}
                required
              />
              <Input name="city" label={getTranslation("city")} required />
              <Input name="state" label={getTranslation("state")} required />
            </S.FormRow>

            <S.FormRow>
              <Input name="street" label={getTranslation("street")} />
              <Input
                name="neighborhood"
                label={getTranslation("neighborhood")}
              />
              <Input name="latitude" label={getTranslation("latitude")} />
              <Input name="longitude" label={getTranslation("longitude")} />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                {getTranslation("cancelar")}
              </S.Button>
              <S.Button type="submit">
                {updateLoading ? <S.Loading /> : getTranslation("cadastrar")}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </MainContainer>
  );
};
